import mixpanel from 'utils/mixpanel';
import Emitter from 'utils/emitter';
import dateDifference from 'utils/dateDifference';
import ReactPixel from 'react-facebook-pixel';

import phoneFormatter from 'phone-formatter';
import React, {useState, useEffect, useContext, Fragment} from 'react';
import {GApageView, GAevent} from 'utils/GA';
import {useNavigate} from 'react-router-dom';
import {ApprovedLoanContext} from 'context/ApprovedLoanContext';
import {UserContext} from 'context/UserContext';

import {UsersAPI, ProductsAPI, LoanAPI} from 'api';
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';
import {TRACKING_EXPIRATION_DAYS} from 'constants';

import ProgressBar from 'components/ProgressBar';
import LoadingModal from 'components/Modals/Loading';

import {REPAYMENT_OPTIONS} from 'constants';

import PrimaryButton from 'components/Button/Primary';
import {CheckIcon, RightIcon} from 'assets/icons_v3/fonts';

import ContractTemplate from 'contracts';
import {STATES_AVAILABLES} from 'constants';
import {DISBURSEMENT_METHODS} from 'constants';
import {trackEvent} from 'utils/dmp_kiwi';

export default function ApprovedLoanContract() {
    const navigate = useNavigate();

    /** Context data */
    const {state: approvedState} = useContext(ApprovedLoanContext);
    const {state: userState, setState: setUserState} = useContext(UserContext);
    const {
        products: {personal_loan},
    } = userState;

    /** useState variables */
    const [showLoader, setShowLoader] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [allContractsSigned, setAllContractsSigned] = useState(false);

    /** Validate props before render */
    useEffect(() => {
        if (
            userState.user.inquiry_status !== 'approved' ||
            approvedState.persona.completed === false
        ) {
            return navigate('/approved-loan/persona');
        }

        if (approvedState.repaymentTerms.tempLoanId === '') {
            LogRocket.captureMessage('Track Repayment Terms Error', {
                extra: {
                    approvedState,
                },
            });

            return navigate('/approved-loan/amount');
        }

        setupContractDetails();
    }, []);

    useEffect(() => {
        const everySigned = contracts.every(contract => contract.signed);
        setAllContractsSigned(everySigned);
    }, [contracts]);

    const setupContractDetails = async () => {
        GApageView('pageview', 'contract');
        mixpanel.track('Loan Contract Start');

        await checkTempLoanExists();
        await verifyRepaymentTermsDate();
        await checkDuplicatedBankAccount();
        await getAllContracts();
    };

    /**
     * Verifica los temrinos de repago, si tienen uno o
     * más de un día creado, se redirige a la página de
     * repayment details para vovler a generar los términos...
     */
    const verifyRepaymentTermsDate = () => {
        const needUpdateRepayments = (() => {
            if (approvedState.repaymentTerms.created_at) {
                const diffDays = dateDifference(
                    approvedState.repaymentTerms.created_at,
                );

                if (diffDays >= 1) {
                    return true;
                }
            }

            return false;
        })();

        if (needUpdateRepayments) {
            navigate('/approved-loan/verify-repayment-details', {
                state: {
                    showWarning: true,
                },
            });
        }
    };

    /**
     * Si la fecha de creación de los términos de repago
     * es menor a TRACKING_EXPIRATION_DAYS (9), se verifica si
     * el usuario tiene una cuenta bancaria duplicada...
     *
     * NOTE: Revisar siempre si el usuario tiene una cuenta,
     * no hace falta la validación de los días porque
     * verifyRepaymentTermsDate() ya se encarga de eso...
     */
    const checkDuplicatedBankAccount = () => {
        if (approvedState.repaymentTerms.created_at) {
            const diffDays = dateDifference(
                approvedState.repaymentTerms.created_at,
            );

            if (diffDays < TRACKING_EXPIRATION_DAYS) {
                const {requirements} = personal_loan;
                const hasDuplicatedAccount = requirements.includes(
                    'duplicate_bank_account',
                );

                if (hasDuplicatedAccount === true) {
                    navigate('/duplicated-bank-account');
                }
            }
        }
    };

    /** Fingerprint functions */
    const {getData: getFingerprintData} = useVisitorData(
        {extendedResult: true},
        {immediate: true},
    );

    const getFingerprintIP = async () => {
        const {ip} = await getFingerprintData({
            ignoreCache: true,
        });

        return ip;
    };

    const setFingerprintData = async () => {
        try {
            await getFingerprintData({
                ignoreCache: true,
                tag: {
                    env: process.env.REACT_APP_FINGERPRINT_ENV,
                    user_id: userState.user.id,
                    place: 'signed_contract',
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    const getAllContracts = async () => {
        try {
            setShowLoader(true);

            const {
                data: {data},
            } = await LoanAPI.getContractByType('personal');

            const contracts = data.map(contract => ({
                ...contract,
                signed: false,
            }));

            setContracts(contracts);
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false);
        }
    };

    const onSignContract = contract => {
        const allContracts = [...contracts];

        setContracts(
            allContracts.map(c =>
                c.id === contract.id ? {...c, signed: true} : c,
            ),
        );

        setSelectedContract(null);
    };

    const onSignAllContracts = async () => {
        try {
            setShowLoader(true);

            if (userState.user.state === STATES_AVAILABLES.florida) {
                await UsersAPI.checkEligibilityEvaluateVeritec(
                    userState.user.id,
                );
            }

            const ip = await getFingerprintIP();
            const athVerification = approvedState.athVerification;
            const {
                amount: {value: amount},
                disbursement: {value: disbursementMethod},
                disbursementProfile: {id: disbursementValue},
                repaymentOption: {value: repaymentMethod},
                repaymentProfile: {id: repaymentValue},
                repaymentTerms: {tempLoanId},
            } = approvedState;

            const {
                data: {
                    loan: {need_verification_call},
                },
            } = await LoanAPI.createLoan({
                user_id: userState.user.id,
                ip,
                lang: 'es',
                amount,
                loanpro_tmp_loan_id: tempLoanId.toString(),
                type:
                    disbursementMethod === DISBURSEMENT_METHODS.immediate
                        ? 'mesalve'
                        : '',
                disbursement_method: disbursementMethod,
                disbursement_value: disbursementValue.toString(),
                disbursement_id: athVerification?.disbursementId ?? '',
                disbursement_token: athVerification?.disbursementToken ?? '',
                repayment_method: repaymentMethod,
                repayment_value: repaymentValue.toString(),
            });

            setFingerprintData();
            contractSignedEvents();

            const {
                data: {data: personal_loan},
            } = await ProductsAPI.getPersonalLoan();

            setUserState(prev => {
                const {application} = personal_loan;

                const updatedPersonalLoan = {
                    ...personal_loan,
                    application: {
                        ...application,
                        need_verification_call,
                    },
                };

                return {
                    ...prev,
                    products: {
                        ...prev.products,
                        personal_loan: updatedPersonalLoan,
                    },
                };
            });

            /**
             * BUG: Revisar esta condicional, cuando se resive
             * need_verification_call true, y se actualiza el state
             * automáticamente redirige a approved-loan/need-verification-call
             * En el middleware no se valida que sea transfer!
             */
            if (
                approvedState.disbursement.value === 'transfer' &&
                need_verification_call
            ) {
                return navigate('/approved-loan/need-verification-call');
            }

            if (
                [
                    DISBURSEMENT_METHODS.transfer,
                    DISBURSEMENT_METHODS.paperCheck,
                ].includes(approvedState.disbursement.value)
            ) {
                return navigate('/moneys-on-the-way');
            }

            if (
                approvedState.disbursement.value ===
                DISBURSEMENT_METHODS.immediate
            ) {
                return navigate('/mesalve-code');
            }
        } catch (error) {
            console.log(error.message);
            const {data} = error?.response;

            if (data.messages === 'ACTIVE_LOAN_ALREADY_EXISTS') {
                return Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Tienes un contrato activo',
                    message: `Parece que actualmente tienes un contrato activo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`,
                });
            }

            if (data.messages === 'LOAN_LESS_7_DAYS') {
                return Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Días de espera...',
                    message: `Deben de pasar 7 días para volver a solicitar otro préstamo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`,
                });
            }

            Emitter.emit('onOpenNotification', {
                type: 'error',
                title: 'Error al firmar tu contrato',
                message: `Parece que hubo un error, póngase en contacto con nuestro equipo de soporte al correo support@kiwicredito.com`,
            });
        } finally {
            setShowLoader(false);
        }
    };

    const contractSignedEvents = () => {
        GAevent('user_action', 'signed_contract');

        window.dataLayer.push({
            event: 'signed_contract',
            email: userState.user.email,
            phone_number: phoneFormatter.format(
                userState.user.phone_number,
                '+1NNNNNNNNNN',
            ),
        });

        window.dataLayer.push({
            event: 'purchase',
            email: userState.user.email,
            phone_number: phoneFormatter.format(
                userState.user.phone_number,
                '+1NNNNNNNNNN',
            ),
            ecommerce: {
                currency: 'USD',
                value: parseInt(approvedState.amount.value),
                transaction_id: approvedState.repaymentTerms.tempLoanId,
                items: [
                    {
                        item_name: `Loan ${approvedState.disbursement.value}`,
                        price: parseInt(approvedState.amount.value),
                        quantity: 1,
                    },
                ],
            },
        });

        ReactPixel.track('Purchase', {
            value: Number(approvedState.amount.value),
            currency: 'USD',
        });

        window.AF('pba', 'event', {
            eventType: 'EVENT',
            eventValue: {
                loan_type: approvedState.disbursement.value,
                value: parseInt(approvedState.amount.value),
            },
            eventName: 'Purchase',
        });

        trackEvent({
            place: '/approved-loan/contract',
            event_type: 'button',
            user_id: userState.user.id,
            pathname: window.location.href,
        });

        if (
            [
                DISBURSEMENT_METHODS.transfer,
                DISBURSEMENT_METHODS.paperCheck,
                DISBURSEMENT_METHODS.ath,
            ].includes(approvedState.disbursement.value)
        ) {
            GAevent('user_action', 'select_transfer');
            window.dataLayer.push({
                event: 'select_transfer',
                email: userState.user.email,
                phone_number: phoneFormatter.format(
                    userState.user.phone_number,
                    '+1NNNNNNNNNN',
                ),
            });
        } else if (
            approvedState.disbursement.value === DISBURSEMENT_METHODS.immediate
        ) {
            GAevent('user_action', 'select_mesalve');
            window.dataLayer.push({
                event: 'select_mesalve',
                email: userState.user.email,
                phone_number: phoneFormatter.format(
                    userState.user.phone_number,
                    '+1NNNNNNNNNN',
                ),
            });
        }
    };

    const onBackward = () => {
        if (selectedContract) {
            return setSelectedContract(null);
        }

        if (
            approvedState.repaymentOption.value === REPAYMENT_OPTIONS.paperCheck
        ) {
            return navigate('/approved-loan/repayment-options');
        }

        navigate('/approved-loan/repayment-profile');
    };

    /**
     * Se consulta en loanpro si existe el loan con el
     * tempLoanId, si no existe, se crea un tempLoan nuevo..
     */
    const checkTempLoanExists = async () => {
        try {
            setShowLoader(true);

            const {
                data: {
                    data: {isActive},
                },
            } = await LoanAPI.checkContractActive({
                tempLoanId: approvedState.repaymentTerms.tempLoanId,
            });

            if (!isActive) {
                const {data} = await LoanAPI.createTempLoan({
                    user_id: userState.user.id,
                    amount: approvedState.amount.value,
                    paymentDay: approvedState.paymentDay.value,
                });

                saveApprovedState({
                    ...approvedState,
                    repaymentTerms: {
                        ...approvedState.repaymentTerms,
                        ...data.loan,
                    },
                });
            }
        } catch (error) {
            console.error(error, 'onRecreateTempLoan ERROR');
        } finally {
            setShowLoader(false);
        }
    };

    return (
        <Fragment>
            <ProgressBar progress="10/10" onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />

            {selectedContract ? (
                <ContractTemplate
                    user={userState.user}
                    selectedContract={selectedContract}
                    onSignContract={onSignContract}
                    disbursementMethod={approvedState.disbursement.value}
                    repaymentTerms={approvedState.repaymentTerms}
                />
            ) : (
                <Fragment>
                    <div className="mb-10">
                        <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                            Contratos de préstamo
                        </h1>
                        <p>Conoce los documentos de tu préstamo:</p>
                    </div>
                    <div className="mb-10 flex flex-col gap-6">
                        {contracts?.map((contract, index) => (
                            <div
                                className="flex items-center cursor-pointer rounded-lg border border-solid border-gray-200-kiwi p-4"
                                onClick={() => setSelectedContract(contract)}
                                key={index}>
                                <div
                                    className={`flex shrink-0 w-6 h-6 rounded-lg mr-4 ${
                                        contract.signed
                                            ? 'bg-blue-kiwi'
                                            : 'border border-solid border-gray-200-kiwi'
                                    }`}>
                                    {contract.signed && (
                                        <CheckIcon className="text-white w-4 m-auto" />
                                    )}
                                </div>
                                <div className="mr-auto">{contract.name}</div>
                                <RightIcon className="text-slate-300-kiwi shrink-0" />
                            </div>
                        ))}
                    </div>
                    <PrimaryButton
                        loading={showLoader}
                        disabled={!allContractsSigned}
                        onClick={onSignAllContracts}
                        className="mt-auto">
                        Firmar contrato
                    </PrimaryButton>
                </Fragment>
            )}
        </Fragment>
    );
}
