import Emitter from 'utils/emitter';
import dateDifference from 'utils/dateDifference';

import React, {useState, useEffect, useContext, Fragment} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from 'context/UserContext';

import {ProductsAPI, LoanAPI} from 'api';
import {useVisitorData} from '@fingerprintjs/fingerprintjs-pro-react';

import ProgressBar from 'components/ProgressBar';
import LoadingModal from 'components/Modals/Loading';

import PrimaryButton from 'components/Button/Primary';
import {CheckIcon, RightIcon} from 'assets/icons_v3/fonts';

import ContractTemplate from 'contracts';
import {trackEvent} from 'utils/dmp_kiwi';
import {CreditBuilderContext} from 'context/CreditBuilderContext';

export default function CreditBuilderContract() {
    const navigate = useNavigate();

    /** Context data */
    const {
        state: creditState,
        saveState: saveCreditState,
        resetState: resetCreditState,
    } = useContext(CreditBuilderContext);

    const {state: userState, setState: setUserState} = useContext(UserContext);

    /** useState variables */
    const [showLoader, setShowLoader] = useState(false);
    const [contracts, setContracts] = useState([]);
    const [selectedContract, setSelectedContract] = useState(null);
    const [allContractsSigned, setAllContractsSigned] = useState(false);

    /** Validate props before render */
    useEffect(() => {
        if (
            userState.user.inquiry_status !== 'approved' ||
            creditState.persona.completed === false
        ) {
            return navigate('/credit-builder/persona');
        }

        if (creditState.repaymentTerms.tempLoanId === '') {
            return navigate('/credit-builder/repayment-terms');
        }

        setupContractDetails();
    }, []);

    useEffect(() => {
        const everySigned = contracts.every(contract => contract.signed);
        setAllContractsSigned(everySigned);
    }, [contracts]);

    const setupContractDetails = async () => {
        checkTempLoanExists();
        verifyUpToDateRepaymentTerms();
        getAllContracts();
    };

    /**
     * Verifies the repayment terms. If they have been created
     * for two or more days, it redirects to the buy course
     * page to start again...
     */
    const verifyUpToDateRepaymentTerms = async () => {
        const isOneDayAfter = (() => {
            if (creditState.repaymentTerms.created_at) {
                const diffDays = dateDifference(
                    creditState.repaymentTerms.created_at,
                );

                if (diffDays >= 2) {
                    return true;
                }
            }

            return false;
        })();

        if (isOneDayAfter) {
            await resetCreditState();
            navigate('/credit-builder/buy-course');
        }
    };

    /** Fingerprint functions */
    const {getData: getFingerprintData} = useVisitorData(
        {extendedResult: true},
        {immediate: true},
    );

    const getFingerprintIP = async () => {
        const {ip} = await getFingerprintData({
            ignoreCache: true,
        });

        return ip;
    };

    const getAllContracts = async () => {
        try {
            setShowLoader(true);

            const {
                data: {data},
            } = await LoanAPI.getContractByType('cb');

            const contracts = data.map(contract => ({
                ...contract,
                signed: false,
            }));

            setContracts(contracts);
        } catch (error) {
            console.error(error);
        } finally {
            setShowLoader(false);
        }
    };

    const onSignContract = () => {
        const allContracts = [...contracts];

        setContracts(
            allContracts.map(c =>
                c.id === selectedContract.id ? {...c, signed: true} : c,
            ),
        );

        setSelectedContract(null);
    };

    const onSignAllContracts = async () => {
        try {
            setShowLoader(true);

            const ip = await getFingerprintIP();
            await CreditBuilderAPI.signContract({
                ip,
                user_id: userState.user.id,
                loanpro_tmp_loan_id:
                    creditState.repaymentTerms.tempLoanId.toString(),
                product_id: creditState.course.id,
                amount: creditState.course.price.toString(),
            });

            const {
                data: {data: credit_builder},
            } = await ProductsAPI.getCreditBuilder();

            setUserState({
                ...userState,
                products: {
                    ...userState.products,
                    credit_builder,
                },
            });

            trackEvent({
                place: '/credit-builder/contract',
                event_type: 'button',
                pathname: window.location.href,
                user_id: userState.user.id,
            });

            await resetCreditState();
            navigate('/credit-builder/success');
        } catch (error) {
            console.log(error.message);
            const {data} = error.response;

            if (data.messages === 'ACTIVE_LOAN_ALREADY_EXISTS') {
                return Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Tienes un contrato activo',
                    message: `Parece que actualmente tienes un contrato activo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`,
                });
            }

            if (data.messages === 'LOAN_LESS_7_DAYS') {
                return Emitter.emit('onOpenNotification', {
                    type: 'error',
                    title: 'Días de espera...',
                    message: `Deben de pasar 7 días para volver a solicitar otro préstamo, puedes escribir a nuestro equipo de soporte al correo support@kiwicredito.com`,
                });
            }

            Emitter.emit('onOpenNotification', {
                type: 'error',
                title: 'Error al firmar tu contrato',
                message: `Parece que hubo un error, póngase en contacto con nuestro equipo de soporte al correo support@kiwicredito.com`,
            });
        } finally {
            setShowLoader(false);
        }
    };

    const onBackward = () => {
        if (selectedContract) {
            return setSelectedContract(null);
        }

        navigate('/credit-builder/payment-profile');
    };

    /**
     * Se consulta en loanpro si existe el loan con el
     * tempLoanId, si no existe, se crea un tempLoan nuevo..
     */
    const checkTempLoanExists = async () => {
        try {
            const {
                data: {
                    data: {isActive},
                },
            } = await LoanAPI.checkContractActive({
                tempLoanId: creditState.repaymentTerms.tempLoanId,
            });

            if (!isActive) {
                const {data} = await LoanAPI.createTempLoanCreditBuilder({
                    user_id: userState.user.id,
                    price_id: creditState.course.price_id,
                    preferred_payment_date: creditState.paymentDay.value,
                    product_id: creditState.course.id,
                });

                saveCreditState({
                    ...creditState,
                    repaymentTerms: {
                        ...creditState.repaymentTerms,
                        ...data.loan,
                    },
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Fragment>
            <ProgressBar progress="10/10" onBackward={onBackward} />
            <LoadingModal isVisible={showLoader} />

            {selectedContract ? (
                <ContractTemplate
                    user={userState.user}
                    selectedContract={selectedContract}
                    onSignContract={onSignContract}
                    disbursementMethod="transfer"
                    repaymentTerms={creditState.repaymentTerms}
                    selectedCourse={creditState.course}
                />
            ) : (
                <Fragment>
                    <div className="mb-10">
                        <h1 className="text-dark-kiwi font-semibold text-2xl mb-4">
                            Contratos de préstamo
                        </h1>
                        <p>Conoce los documentos de tu préstamo:</p>
                    </div>
                    <div className="mb-10 flex flex-col gap-6">
                        {contracts?.map((contract, index) => (
                            <div
                                className="flex items-center cursor-pointer rounded-lg border border-solid border-gray-200-kiwi p-4"
                                onClick={() => setSelectedContract(contract)}
                                key={index}>
                                <div
                                    className={`flex shrink-0 w-6 h-6 rounded-lg mr-4 ${
                                        contract.signed
                                            ? 'bg-blue-kiwi'
                                            : 'border border-solid border-gray-200-kiwi'
                                    }`}>
                                    {contract.signed && (
                                        <CheckIcon className="text-white w-4 m-auto" />
                                    )}
                                </div>
                                <div className="mr-auto">{contract.name}</div>
                                <RightIcon className="text-slate-300-kiwi shrink-0" />
                            </div>
                        ))}
                    </div>
                    <PrimaryButton
                        loading={showLoader}
                        disabled={!allContractsSigned}
                        onClick={onSignAllContracts}
                        className="mt-auto">
                        Firmar contrato
                    </PrimaryButton>
                </Fragment>
            )}
        </Fragment>
    );
}
