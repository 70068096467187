import React, {useContext} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {UserContext} from 'context/UserContext';

/**
 * Prevenir que el usuario regrese al flujo firma de contratos, si ya se
 * firmó el contrato y necesita una llamada de verificación
 */
export const NeedVerificationMiddleware = () => {
    const {state: userState} = useContext(UserContext);

    const {
        products: {
            personal_loan: {application},
        },
    } = userState;

    return application?.need_verification_call ? (
        <Navigate to="/approved-loan/need-verification-call" />
    ) : (
        <Outlet />
    );
};
