import React, {lazy, Suspense} from 'react';

/**
 * Personal Loan Contracts
 */

/** Puerto Rico - Español Contracts */
const EsignConsentContract = lazy(
    () => import('./personalLoan/PR/ES/EsignConsentContract'),
);
const PaymentElectionAuthorizationContract = lazy(
    () => import('./personalLoan/PR/ES/PaymentElectionAuthorizationContract'),
);
const PersonalPaymentsContract = lazy(
    () => import('./personalLoan/PR/ES/PersonalPaymentsContract'),
);
const PrivacyPolicyContract = lazy(
    () => import('./personalLoan/PR/ES/PrivacyPolicyContract'),
);
const WaiverArbitrationClauseContract = lazy(
    () => import('./personalLoan/PR/ES/WaiverArbitrationClauseContract'),
);

/** Florida - Español Contracts */
const EsignConsentContractFL = lazy(
    () => import('./personalLoan/FL/ES/EsignConsentContract'),
);
const WaiverArbitrationClauseContractFL = lazy(
    () => import('./personalLoan/FL/ES/WaiverArbitrationClauseContract'),
);
const PaymentElectionAuthorizationContractFL = lazy(
    () => import('./personalLoan/FL/ES/PaymentElectionAuthorizationContract'),
);
const DeferredAgreementContractFL = lazy(
    () => import('./personalLoan/FL/ES/DeferredAgreementContract'),
);

/**
 * Credit Builder Contracts
 */

/** Puerto Rico - Español Contracts */
const PrivacyPolicyContractCB = lazy(
    () => import('./creditBuilder/PR/ES/PrivacyPolicyContract'),
);
const WaiverArbitrationClauseContractCB = lazy(
    () => import('./creditBuilder/PR/ES/WaiverArbitrationClauseContract'),
);
const EsignConsentContractCB = lazy(
    () => import('./creditBuilder/PR/ES/EsignConsentContract'),
);
const PaymentElectionAuthorizationContractCB = lazy(
    () => import('./creditBuilder/PR/ES/PaymentElectionAuthorizationContract'),
);
const CreditbuilderContractCB = lazy(
    () => import('./creditBuilder/PR/ES/CreditbuilderContract'),
);

/** Florida - Español Contracts */
const PrivacyPolicyContractCBFL = lazy(
    () => import('./creditBuilder/FL/ES/PrivacyPolicyContract'),
);
const WaiverArbitrationClauseContractCBFL = lazy(
    () => import('./creditBuilder/FL/ES/WaiverArbitrationClauseContract'),
);
const EsignConsentContractCBFL = lazy(
    () => import('./creditBuilder/FL/ES/EsignConsentContract'),
);
const PaymentElectionAuthorizationContractCBFL = lazy(
    () => import('./creditBuilder/FL/ES/PaymentElectionAuthorizationContract'),
);
const CreditbuilderContractCBFL = lazy(
    () => import('./creditBuilder/FL/ES/CreditbuilderContract'),
);

export default ({...props}) => {
    const lang = 'es';
    const {selectedContract} = props;

    const contractsTemplates = {
        personal: {
            puerto_rico: {
                es: {
                    'loans/contract/personal-payments': (
                        <PersonalPaymentsContract {...props} />
                    ),
                    'loans/contract/privacy-policy': (
                        <PrivacyPolicyContract {...props} />
                    ),
                    'loans/contract/waiver-arbitration-clause': (
                        <WaiverArbitrationClauseContract {...props} />
                    ),
                    'loans/contract/esign-consent': (
                        <EsignConsentContract {...props} />
                    ),
                    'loans/contract/payment-election-authorization': (
                        <PaymentElectionAuthorizationContract {...props} />
                    ),
                },
            },
            florida: {
                es: {
                    'loans/fl/contract/esign-consent': (
                        <EsignConsentContractFL {...props} />
                    ),
                    'loans/fl/contract/waiver-arbitration-clause': (
                        <WaiverArbitrationClauseContractFL {...props} />
                    ),
                    'loans/fl/contract/deferred-agreement': (
                        <DeferredAgreementContractFL {...props} />
                    ),
                    'loans/fl/contract/payment-election-authorization': (
                        <PaymentElectionAuthorizationContractFL {...props} />
                    ),
                },
            },
        },
        cb: {
            puerto_rico: {
                es: {
                    'loans/contract/cb-payments': (
                        <CreditbuilderContractCB {...props} />
                    ),
                    'loans/contract/cb-payment-election-authorization': (
                        <PaymentElectionAuthorizationContractCB {...props} />
                    ),
                    'loans/contract/cb-esign-consent': (
                        <EsignConsentContractCB {...props} />
                    ),
                    'loans/contract/cb-waiver-arbitration-clause': (
                        <WaiverArbitrationClauseContractCB {...props} />
                    ),
                    'loans/contract/cb-privacy-policy': (
                        <PrivacyPolicyContractCB {...props} />
                    ),
                },
            },
            florida: {
                es: {
                    'loans/fl/contract/credit-builder': (
                        <CreditbuilderContractCBFL {...props} />
                    ),
                    'loans/fl/contract/credit-builder/privacy-policy': (
                        <PrivacyPolicyContractCBFL {...props} />
                    ),
                    'loans/fl/contract/credit-builder/esign-consent': (
                        <EsignConsentContractCBFL {...props} />
                    ),
                    'loans/fl/contract/credit-builder/waiver-arbitration-clause':
                        <WaiverArbitrationClauseContractCBFL {...props} />,
                    'loans/fl/contract/credit-builder/payment-election-authorization':
                        <PaymentElectionAuthorizationContractCBFL {...props} />,
                },
            },
        },
    };

    const {type, state, path} = selectedContract;
    const Contract = contractsTemplates[type]?.[state]?.[lang]?.[path];

    if (!Contract) {
        /** TODO: Crear un mejor fallback emptystate */
        return (
            <div>
                Parese que no existe contrato para esta vista, por favor
                contáctanos a <strong>support@kiwicredito.com</strong> para
                solucionar tu problema.
            </div>
        );
    }

    return <Suspense>{Contract}</Suspense>;
};
